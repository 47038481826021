import { FC, useContext, useEffect, useState } from "react";
import { decode } from "html-entities";
import { RWebShare } from "react-web-share";
import SocialMedia from "./SocialMedia";
import vCardJS from "vcards-js";
import axios from "axios";

type Props = {
  clientData: any;
  generalSettings: any;
};

const Header: FC<Props> = ({ clientData, generalSettings }) => {
  const menuButton = document.getElementById("hamburger_menu");
  const [adData, setAdData]: any = useState<any>();
  const [lockIcon, setLockIcon] = useState<any>();

  const iconItems = clientData.icons_order
    ? clientData.icons_order.split(",")
    : [];

  const getBase64FromUrl = async (url: any) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return (
    <div className="site-header sticky top-0">
      <div className="flex flex-col">
        <div className="w-full flex flex-row items-center justify-between mx-auto mt-2 relative ">
          {clientData.icon_petfriendly_active === "1" &&
            clientData.is_business === "1" &&
            generalSettings.icon_petfriendly && (
              <img
                src={generalSettings?.icon_petfriendly}
                height={50}
                width={50}
                alt=""
                className="social-icon-color-trans absolute max-w-[20%] left-[5vw]"
              ></img>
            )}
          {(clientData.is_business === "0" ||
            clientData.is_business === "2") && (
            <img
              src={clientData?.QR_Code_image_URL}
              height={80}
              width={80}
              alt=""
              className="absolute max-w-[20%] left-[5vw]"
            ></img>
          )}
          {clientData.logo_url && (
            <div className="w-full flex flex-row justify-center">
              <img
                alt="Logo"
                src={clientData.logo_url}
                className={`max-h-[17vh] max-w-[50vw] ${
                  clientData.is_bnb === "1" && "cursor-pointer"
                } `}
                onClick={() =>
                  clientData.is_bnb === "1" &&
                  window.open(`https://airstay.app`, "_blank")
                }
              />
            </div>
          )}
          {generalSettings?.icon_hamburger && (
            <img
              src={generalSettings?.icon_hamburger}
              height={50}
              width={50}
              alt=""
              className={`social-icon-color-trans fixed cursor-pointer right-[5vw] ${
                clientData.logo_url ? "" : "mt-12"
              }`}
              onClick={() => document.getElementById("hamburger_menu")?.click()}
            ></img>
          )}
        </div>
        <div
          className={`text-center text-600 theme-button-background theme-text-color trans ${
            clientData.logo_url ? "" : "mt-14"
          } `}
          translate="no"
          dangerouslySetInnerHTML={{
            __html: decode(clientData.Title_tag_WebSite),
          }}
        ></div>
      </div>
      
    </div>
  );
};
export default Header;
